export default defineComponent({
  name: "SentenceList",
  setup(props, { slots }) {
    return () => {
      const slot = slots.default && slots.default();
      const children = slot[0]?.children;

      let rendered = [];
      if (Array.isArray(children)) {
        children.forEach((child, index) => {
          if (rendered.length > 0)
            rendered.push(
              `${children.length > 2 ? "," : ""} ${
                index == children.length - 1 ? "and " : ""
              }`
            );
          rendered.push(child);
        });
      } else rendered = slot;

      return rendered;
    };
  },
});
